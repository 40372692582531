<template>
  <vs-sidebar
    click-not-close
    position-right
    parent="body"
    default-index="1"
    color="primary"
    class="add-new-data-sidebar items-no-padding"
    spacer
    v-model="isSidebarActiveLocal"
  >
    <div class="mt-6 flex items-center justify-between px-6">
      <h4>
        {{
          Object.entries(this.data).length === 0 ? $t("AddNew") : $t("Update")
        }}
      </h4>
      <feather-icon
        icon="XIcon"
        @click.stop="isSidebarActiveLocal = false"
        class="cursor-pointer"
      ></feather-icon>
    </div>

    <vs-divider class="mb-0"></vs-divider>

    <VuePerfectScrollbar
      class="scroll-area--data-list-add-new"
      :settings="settings"
    >
      <vs-card title="icon">
        <h4>* {{ $t("LoadTypeNameAr") }}</h4>
        <vs-input
          class="w-full"
          v-model="TypeNameAr"
          v-validate="'required'"
          name="Name"
        />
      </vs-card>

      <vs-card title="icon">
        <h4>* {{ $t("LoadTypeNameEn") }}</h4>
        <vs-input
          class="w-full"
          v-model="TypeNameEn"
          v-validate="'required'"
          name="Name"
        />
      </vs-card>
    </VuePerfectScrollbar>

    <div class="flex flex-wrap items-center p-6" slot="footer">
      <vs-button class="mr-6" @click="submitData">{{ $t("Submit") }}</vs-button>
      <vs-button
        type="border"
        color="danger"
        @click="isSidebarActiveLocal = false"
        >{{ $t("Cancel") }}</vs-button
      >
    </div>
  </vs-sidebar>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";

export default {
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
      default: () => {},
    },
  },

  watch: {
    isSidebarActive(val) {
      debugger;
      if (!val) return;
      if (Object.entries(this.data).length === 0) {
        this.initValues();
        this.$validator.reset();
      } else {
        let { ID, TypeNameAr, TypeNameEn } = JSON.parse(
          JSON.stringify(this.data)
        );
        this.ID = ID;
        this.TypeNameAr = TypeNameAr;
        this.TypeNameEn = TypeNameEn;
      }
    },
  },

  data() {
    return {
      ID: null,
      TypeNameAr: "",
      TypeNameEn:"",
      settings: {
        // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: 0.6,
      },
    };
  },

  computed: {
    isSidebarActiveLocal: {
      get() {
        return this.isSidebarActive;
      },
      set(val) {
        if (!val) {
          this.$emit("closeSidebar");
        }
      },
    },
    isFormValid() {
      return !this.errors.any() && this.ID;
    },
  },

  methods: {
    initValues() {
      if (this.data.ID) return;
      this.ID = null;
      this.TypeNameAr = "";
      this.TypeNameEn = "";
    },

    submitData() {
      debugger;
      this.$validator.validateAll().then((result) => {
        if (result) {
          const obj = {
            ID: this.data.Id,
            TypeNameAr: this.TypeNameAr,
            TypeNameEn: this.TypeNameEn,
          };
          if (this.data.Id !== null && this.data.Id > 0) {
            debugger;
            this.$store
              .dispatch("LoadTypesList/UpdateLoadType", obj)
              .then(() => {
                debugger;

                this.$store
                  .dispatch("LoadTypesList/GetAllLoadTypes")
                  .then(() => {
                    this.$vs.loading.close();
                  });
                window.showSuccess();
              })
              .catch((err) => {
                console.error(err);
              });
          } else {
            debugger;
            delete obj.ID;
            obj.popularity = 0;
            this.$store
              .dispatch("LoadTypesList/AddNewLoadType", obj)
              .then(() => {
                debugger;
                this.$store
                  .dispatch("LoadTypesList/GetAllLoadTypes")
                  .then(() => {
                    this.$vs.loading.close();
                  });
                window.showSuccess();
              })
              .catch((err) => {
                console.error(err);
              });
          }
          this.$emit("closeSidebar");

          this.initValues();
        }
      });
    },
  },

  components: {
    VuePerfectScrollbar,
  },
};
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 400px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
  height: calc(100% - 4.3rem);
}
</style>
