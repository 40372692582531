/*=========================================================================================
  File Name: moduleCourseClassActions.js
  Description: CourseClass Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/
import axios from "@/axios.js";
export default {
  AddNewLoadType({ commit }, item) {
    debugger;
    return new Promise((resolve, reject) => {
      axios
        .post("api/LoadTypes/AddNewLoadType", item)
        .then(response => {
          commit("ADD_LoadTypes", Object.assign(item, { ID: response.data.ID }));
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  GetAllLoadTypes({ commit }) {
    debugger;
    return new Promise((resolve, reject) => {
      axios
        .get("api/LoadTypes/GetAllLoadTypes")
        .then(response => {
          if (response.status == 200) {
            commit("SET_LoadTypes", response.data);
            resolve(response);
          }

        })
        .catch((error) => { reject(error) })
    })
  },

  GetLoadTypeById(context, itemid) {
    return new Promise((resolve, reject) => {
      axios.get("api/LoadTypes/GetLoadTypeById?ID="+ itemid)
        .then((response) => {
          debugger

          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },


  UpdateLoadType({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios
        .put("api/LoadTypes/UpdateLoadType" , item)
        .then(response => {
          debugger
          commit("UPDATE_LoadTypes", item);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  DeleteLoadType({ commit }, item) {
      debugger;
    return new Promise((resolve, reject) => {
      axios
        .post("api/LoadTypes/DeleteLoadType" , item)
        .then(response => {
          resolve(response);
          commit("REMOVE_LoadTypes", item);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

};
