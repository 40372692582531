export default {

  ADD_LoadTypes(state, item) {
    state.LoadTypes.unshift(item);
  },

  SET_LoadTypes(state, LoadTypes) {
    state.LoadTypes = LoadTypes;
  },

  UPDATE_LoadTypes(state, item) {
    debugger;
    const Index = state.LoadTypes.findIndex(p => p.Id == item.ID);
    Object.assign(state.LoadTypes[Index], item);
  },

  REMOVE_LoadTypes(state, itemId) {
    const ItemIndex = state.LoadTypes.findIndex(p => p.Id == itemId);
    state.LoadTypes.splice(ItemIndex, 1);
  }

};
